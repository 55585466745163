/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentEmployeeUpdate } from '../models/CurrentEmployeeUpdate';
import type { Employee } from '../models/Employee';
import type { Employees } from '../models/Employees';
import type { EmployeeUpdate } from '../models/EmployeeUpdate';
import type { SyncStatus } from '../models/SyncStatus';
import type { TeamAssignments } from '../models/TeamAssignments';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmployeesService {
    /**
     * get status of employees sync
     * @param tenantId tenant_id
     * @returns SyncStatus sync status
     * @throws ApiError
     */
    public static getEmployeesSyncStatus(
        tenantId: string,
    ): CancelablePromise<SyncStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/resources/employee_sync/sync_status',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * sync employees
     * @param tenantId tenant_id
     * @returns void
     * @throws ApiError
     */
    public static syncEmployees(
        tenantId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/resources/employee_sync/sync',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * list employees
     * @param jobCategory job_category
     * @returns Employees employees found with job_category param
     * @throws ApiError
     */
    public static getEmployees(
        jobCategory?: string,
    ): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees',
            query: {
                'job_category': jobCategory,
            },
        });
    }
    /**
     * update employee
     * @param id id
     * @param requestBody
     * @returns Employee employee updated
     * @throws ApiError
     */
    public static updateEmployee(
        id: string,
        requestBody?: EmployeeUpdate,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/employees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * show employee
     * @returns Employee employee found
     * @throws ApiError
     */
    public static getCurrentEmployee(): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/me/employee',
        });
    }
    /**
     * update current employee
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateCurrentEmployee(
        requestBody?: CurrentEmployeeUpdate,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/me/employee',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `missing required beta_user param`,
            },
        });
    }
    /**
     * list employees
     * @param tenantId tenant_id
     * @returns Employees successful
     * @throws ApiError
     */
    public static getTenantEmployees(
        tenantId: string,
    ): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/employees',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
    /**
     * list employees team_assignments
     * @param tenantId tenant_id
     * @returns TeamAssignments successful
     * @throws ApiError
     */
    public static getCurrentEmployeeTeamAssignments(
        tenantId: string,
    ): CancelablePromise<TeamAssignments> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/me/team_assignments',
            path: {
                'tenant_id': tenantId,
            },
        });
    }
}
