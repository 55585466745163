import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { toolKeys } from "~api/toolKeys.ts";
import { getRelevantSearchText, getTools } from "~api/tools.ts";
import {
  type Employee,
  type GeoPosition,
  ToolAdministrationService,
  type ToolForm,
  type ToolHistoryEntry,
} from "~generated";

import type { QueryClient, UseQueryOptions } from "@tanstack/react-query";
import type { FilterParams } from "~api/tools.ts";

export function useInfiniteAllTools(filters: FilterParams) {
  const searchText = getRelevantSearchText(filters);

  return useInfiniteQuery({
    queryKey: toolKeys.infiniteList({ ...filters, searchText }),
    queryFn: async ({ pageParam: page }) =>
      getTools({ ...filters, searchText, page }),
    getNextPageParam: (lastPage) => lastPage.next_page,
    initialPageParam: 1,
  });
}

export function useToolDetail({ id }: { id?: string }) {
  return useQuery({
    queryKey: toolKeys.detail(id || ""),
    queryFn: async () => ToolAdministrationService.getToolDetail(id || ""),
    enabled: !!id,
  });
}

export function useCreateTool() {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (toolForm: ToolForm) =>
      ToolAdministrationService.createTool(toolForm),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: toolKeys.base() });
    },
  });
  return mutation;
}

interface UpdateToolArgs {
  id: string;
  toolForm: ToolForm;
  originalRequestTimestamp?: string;
}
export async function updateTool({
  id,
  toolForm,
  originalRequestTimestamp = new Date().toISOString(),
}: UpdateToolArgs) {
  return ToolAdministrationService.updateTool(
    id,
    originalRequestTimestamp,
    toolForm,
  );
}

export function useUpdateTool<TContext>({
  onMutate = () => ({}) as TContext,
}: {
  onMutate?: (
    queryClient: QueryClient,
    updateToolArgs: UpdateToolArgs,
  ) => TContext;
} = {}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: toolKeys.updateToolMutationKey,
    mutationFn: updateTool,
    onMutate: (variables) => onMutate && onMutate(queryClient, variables),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: toolKeys.base() });
    },
  });
  return mutation;
}

interface TakeToolArgs {
  id: string;
  position: GeoPosition | null;
  originalRequestTimestamp?: string;
}
export async function takeTool({
  id,
  position,
  originalRequestTimestamp = new Date().toISOString(),
}: TakeToolArgs) {
  return ToolAdministrationService.takeTool(id, originalRequestTimestamp, {
    position,
  });
}
export function useTakeTool<TContext>({
  onMutate = () => ({}) as TContext,
}: {
  onMutate?: (queryClient: QueryClient, takeToolArgs: TakeToolArgs) => TContext;
} = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: toolKeys.takeToolMutationKey,
    mutationFn: takeTool,
    onMutate: (variables) => onMutate && onMutate(queryClient, variables),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: toolKeys.base() });
    },
  });
}

interface ReturnToolArgs {
  id: string;
  position: GeoPosition | null;
  originalRequestTimestamp?: string;
}
export async function returnTool({
  id,
  position,
  originalRequestTimestamp = new Date().toISOString(),
}: ReturnToolArgs) {
  return ToolAdministrationService.returnTool(id, originalRequestTimestamp, {
    position,
  });
}
export function useReturnTool<TContext>({
  onMutate = () => ({}) as TContext,
}: {
  onMutate?: (
    queryClient: QueryClient,
    returnToolArgs: ReturnToolArgs,
  ) => TContext;
} = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: toolKeys.returnToolMutationKey,
    mutationFn: returnTool,
    onMutate: (variables) => onMutate && onMutate(queryClient, variables),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: toolKeys.base() });
    },
  });
}

async function getToolsResponsibleEmployees() {
  const employees = await ToolAdministrationService.getResponsibleEmployees();
  if (!employees || !employees.employees) {
    return [];
  }

  return employees.employees;
}

export function useToolsResponsibleEmployees(
  options?: UseQueryOptions<
    Employee[],
    Error,
    Employee[],
    ReturnType<typeof toolKeys.responsibleEmployees>
  >,
) {
  return useQuery({
    ...options,
    queryKey: toolKeys.responsibleEmployees(),
    queryFn: async () => getToolsResponsibleEmployees(),
  });
}

async function getToolsManagers() {
  const managers = await ToolAdministrationService.getManagers();
  if (!managers || !managers.employees) {
    return [];
  }

  return managers.employees;
}

export function useToolsManagers(
  options?: UseQueryOptions<
    Employee[],
    Error,
    Employee[],
    ReturnType<typeof toolKeys.managers>
  >,
) {
  return useQuery({
    ...options,
    queryKey: toolKeys.managers(),
    queryFn: () => getToolsManagers(),
  });
}
async function getToolsManufacturers() {
  const response = await ToolAdministrationService.getManufacturers();
  if (!response || !response.manufacturers) {
    return [];
  }

  return response.manufacturers;
}

export function useToolsManufacturers(
  options?: UseQueryOptions<
    string[],
    Error,
    string[],
    ReturnType<typeof toolKeys.manufacturers>
  >,
) {
  return useQuery({
    ...options,
    queryKey: toolKeys.manufacturers(),
    queryFn: () => getToolsManufacturers(),
  });
}

export function useToolsAdditionalData() {
  return useQuery({
    queryKey: toolKeys.additionalData(),
    queryFn: () => ToolAdministrationService.getAdditionalData(),
  });
}

async function getToolHistory(id: string) {
  const response = await ToolAdministrationService.getToolHistory(id);
  if (!response || !response.history) {
    return [];
  }
  return response.history;
}

export function useToolHistory({
  id,
  options,
}: {
  id: string;
  options?: UseQueryOptions<
    ToolHistoryEntry[],
    Error,
    ToolHistoryEntry[],
    ReturnType<typeof toolKeys.history>
  >;
}) {
  return useQuery({
    queryKey: toolKeys.history(id),
    queryFn: () => getToolHistory(id),
    ...options,
  });
}
