/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingUpdateAdjacentLocationValuesEventBody = {
    action: TimeTrackingUpdateAdjacentLocationValuesEventBody.action;
    reference: string;
    resource: Resource;
    previous_work_to_start_location_meters?: (number | null);
    previous_work_to_start_location_seconds?: (number | null);
    start_to_current_work_location_meters?: (number | null);
    start_to_current_work_location_seconds?: (number | null);
    current_work_to_stop_location_meters?: (number | null);
    current_work_to_stop_location_seconds?: (number | null);
    stop_to_next_work_location_meters?: (number | null);
    stop_to_next_work_location_seconds?: (number | null);
};
export namespace TimeTrackingUpdateAdjacentLocationValuesEventBody {
    export enum action {
        UPDATE_ADJACENT_LOCATION_VALUES = 'update_adjacent_location_values',
    }
}

