import { GeoConfidence } from "~generated";

import type { Employee } from "~generated";

export function isSuperiorRequired(employee: Employee) {
  return employee.superior_required && !employee.superior_id;
}

export function isAmbiguousAddress(employee: Employee) {
  return !(
    employee.residence_address_confidence === GeoConfidence.HIGH ||
    employee.residence_address_confidence === GeoConfidence.MANUAL ||
    employee.residence_address_text === null
  );
}
