/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Tool = {
    abbreviation: string;
    active: boolean;
    billable_from: string | null;
    category_id: string;
    readonly id: string;
    license_plate_number: string | null;
    manager_id: string;
    name: string;
    serial_number: string | null;
    team_ids: Array<string>;
    tenant_id: string;
    tool_id: string;
    responsible_employee_id: string | null;
    responsible_employee_id_updated_at: string | null;
    previous_responsible_employee_id: string | null;
    previous_responsibility_taken_at: string | null;
    previous_responsibility_returned_at: string | null;
    responsibility_state: Tool.responsibility_state;
};
export namespace Tool {
    export enum responsibility_state {
        AVAILABLE = 'available',
        TAKEN = 'taken',
    }
}

