import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import {
  TimeTrackingApproveEventBody,
  TimeTrackingAutoStopEventBody,
  TimeTrackingForceStopEventBody,
  TimeTrackingSessionStartEventBody,
  TimeTrackingStartEventBody,
  TimeTrackingStopEventBody,
  TimeTrackingUnapproveEventBody,
  TimeTrackingUpdateCommuteTypeEventBody,
  TimeTrackingsService,
} from "~generated";

import { timeEntriesKeys } from "./timeEntries.ts";
import { timeTrackingKeys } from "./timeTracking.ts";

import type { QueryClient } from "@tanstack/react-query";
import type { Tenant, TimeTrackingEvent } from "~generated";

export interface TimeTrackingSessionStartEvent extends TimeTrackingEvent {
  body: TimeTrackingSessionStartEventBody;
}
export function isTimeTrackingSessionStartEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingSessionStartEvent {
  return (
    event.body.action === TimeTrackingSessionStartEventBody.action.SESSION_START
  );
}

export interface TimeTrackingStartEvent extends TimeTrackingEvent {
  body: TimeTrackingStartEventBody;
}
export function isTimeTrackingStartEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingStartEvent {
  return event.body.action === TimeTrackingStartEventBody.action.START;
}

export interface TimeTrackingStopEvent extends TimeTrackingEvent {
  body: TimeTrackingStopEventBody;
}
export function isTimeTrackingStopEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingStopEvent {
  return event.body.action === TimeTrackingStopEventBody.action.STOP;
}

export interface TimeTrackingApproveEvent extends TimeTrackingEvent {
  body: TimeTrackingApproveEventBody;
}
export function isTimeTrackingApproveEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingApproveEvent {
  return event.body.action === TimeTrackingApproveEventBody.action.APPROVE;
}

export interface TimeTrackingUnapproveEvent extends TimeTrackingEvent {
  body: TimeTrackingUnapproveEventBody;
}
export function isTimeTrackingUnapproveEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingUnapproveEvent {
  return event.body.action === TimeTrackingUnapproveEventBody.action.UNAPPROVE;
}

export interface TimeTrackingAutoStopEvent extends TimeTrackingEvent {
  body: TimeTrackingAutoStopEventBody;
}
export function isTimeTrackingAutoStopEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingAutoStopEvent {
  return event.body.action === TimeTrackingAutoStopEventBody.action.AUTO_STOP;
}

export interface TimeTrackingForceStopEvent extends TimeTrackingEvent {
  body: TimeTrackingForceStopEventBody;
}
export function isTimeTrackingForceStopEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingForceStopEvent {
  return event.body.action === TimeTrackingForceStopEventBody.action.FORCE_STOP;
}

export interface TimeTrackingUpdateCommuteTypeEvent extends TimeTrackingEvent {
  body: TimeTrackingUpdateCommuteTypeEventBody;
}
export function isTimeTrackingUpdateCommuteTypeEvent(
  event: TimeTrackingEvent,
): event is TimeTrackingUpdateCommuteTypeEvent {
  return (
    event.body.action ===
    TimeTrackingUpdateCommuteTypeEventBody.action.UPDATE_COMMUTE_TYPE
  );
}

export function isTimeTrackingStoppyEvent(
  event: TimeTrackingEvent,
): event is
  | TimeTrackingStopEvent
  | TimeTrackingAutoStopEvent
  | TimeTrackingForceStopEvent {
  return (
    isTimeTrackingStopEvent(event) ||
    isTimeTrackingAutoStopEvent(event) ||
    isTimeTrackingForceStopEvent(event)
  );
}

export interface CreateTimeTrackingEventsArgs {
  tenantId: Tenant["id"];
  timeTrackingEvents: TimeTrackingEvent[];
}
export async function createTimeTrackingEvents({
  tenantId,
  timeTrackingEvents,
}: CreateTimeTrackingEventsArgs) {
  return TimeTrackingsService.createTimeTrackingEvent(
    tenantId,
    timeTrackingEvents,
  );
}

export const createTimeTrackingEventsMutationKey = [
  "createTimeTrackingEventsMutation",
];

export function useCreateTimeTrackingEvents<TContext>({
  onMutate = () => ({}) as TContext,
}: {
  onMutate?: (
    queryClient: QueryClient,
    timeTrackingEvents: CreateTimeTrackingEventsArgs,
  ) => TContext;
} = {}) {
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: createTimeTrackingEventsMutationKey,
    mutationFn: createTimeTrackingEvents,
    scope: { id: "events" },

    onMutate: (variables) => onMutate && onMutate(queryClient, variables),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: timeTrackingKeys.base(currentTenant.id),
      });
      await queryClient.invalidateQueries({
        queryKey: timeEntriesKeys.base(),
      });
    },
  });
}
